@tailwind base;
@tailwind components;
@tailwind utilities;

.primary {
    @apply text-blue bg-pink;

    span {
        @apply text-white bg-grey;
    }
}
